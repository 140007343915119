import React from 'react';
import '../assets/styles/core.css'
import config from '../config';

const PrintImage = ({domain, list}) => {
	if(list && Array.isArray(list) && list.length > 0) {
		return list.map((ele, index) => {
			return <div key={Math.random()} className={`carousel-item text-center img-fluid ${index === 0 ? 'active': ''}`}>
				<img src={`${config.assets.products}/${domain}/products/large/${ele}`} className='img-fluid' />
			</div>
		})
	} else {
		return <div className="p-3 fs-6 fw-bold text-center text-dark">No Image!!!</div>
	}
}

const PrintIndicator = ({list, uid}) => {
	if(list && Array.isArray(list) && list.length > 0) {
		return list.map((ele, index) => {
			return <button key={Math.random()} type="button" data-bs-target={`#carouselIndicators-${uid}`} data-bs-slide-to={index} className={index == 0 ? 'active':''} aria-current="true" aria-label={`Slide ${index}`}></button>
		})
	} else {
		return null
	}
}

function ImageCarousel({domain, fileName, uid}) {
	let image = (fileName) ? fileName.split(",") : "";
  	return (
		<div className="col d-flex justify-content-center">
			<div className="card border border-1">
				<div id={`carouselIndicators-${uid}`} className="carousel slide" data-bs-ride="carousel">
					<div className="carousel-indicators">
						<PrintIndicator list={image} uid={uid} />
					</div>
					<div className="carousel-inner">
						<PrintImage domain={domain} list={image} />
					</div>
					{(image && Array.isArray(image) && image.length > 0) ? <>
						<button className="carousel-control-prev" type="button" data-bs-target={`#carouselIndicators-${uid}`} data-bs-slide="prev">
							<span className="carousel-control-prev-icon" aria-hidden="true"></span>
							<span className="visually-hidden">Previous</span>
						</button>
						<button className="carousel-control-next" type="button" data-bs-target={`#carouselIndicators-${uid}`} data-bs-slide="next">
							<span className="carousel-control-next-icon" aria-hidden="true"></span>
							<span className="visually-hidden">Next</span>
						</button>
					</> : null}
				</div>
			</div>
		</div>
	)
}

export default ImageCarousel

