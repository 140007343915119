import React from 'react';
import { Controller } from 'react-hook-form';
import '../assets/styles/core.css'
import ImageCarousel from "./image-carousel";

function CardList({index, control, errors, paginationNo, domain, imgCarousel, placeholder, id, onInput, permission}) {

	const validateSingleQuote = (value) => {
		const regex = /['"]/g;
		return regex.test(value) ? "Single quote and double quote is not allowed." : null;
	};

	return <Controller
        name={`items[${index}].description_playbook`}
        control={control}
        rules={{ validate: validateSingleQuote }} 
        render={({ field }) => (
			<div key={index} className="card bg-light mt-3">
				<ImageCarousel domain={domain} fileName={imgCarousel} uid={Math.random()} />
				<div className="px-3 pt-2 fw-bold fs-6">#{`${paginationNo}.${index+1}`}</div>
				<div className="card-body">
					<textarea {...field} rows="5" onKeyUp={() => onInput(id)} className="form-control" placeholder={placeholder} disabled={(Number(permission))?false:true}></textarea>
					<small className="form-text text-danger">{errors && errors.description_playbook && <p>{errors.description_playbook.message}</p>}</small>
				</div>
			</div>
		)}
	/>
}

export default CardList