export default {
    api: {
        generic: "https://ecomm.api.vyayatatech.com/public",
        // generic: "http://localhost:8000/public",
        image: "",
        googlesheet: ""
    },
    assets: {
        products:"https://storage.googleapis.com/inhouse-ecommerce-sas-public",
        generic:"assets/generic"
    },
    secretKey: "123456789",
    pagination: {
        limitPerPage: 10
    }
}