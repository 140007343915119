// libs
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from "react-js-pagination";
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import Loading from '../components/loading';
import Record404 from '../components/record404';
import CardList from '../components/card-list';
// config & functions 
import ajaxCall from '../utils/ajaxcall';
import config from '../config';
import utils from '../utils';

const Home = () => {
    let query = utils.useQuery();
    const [Placeholder, setPlaceholder] = useState('');
    const [Permission, setPermission] = useState(0);
    const [ListDataCount, setListDataCount] = useState(0);
    const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [IsSubmitted, setIsSubmitted] = useState(false);
	const [Is404, setIs404] = useState(false);
    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const submitButtonRef = useRef(null);
    let isRefSubmit = useRef(false);
    let pageNumber = useRef(null);
    let childRef = useRef([]);

    // get query param
	let paginationNo = query.get("page");
	let activePage = (utils.isNumeric(paginationNo)) ? parseInt(paginationNo) : 1;
    let {domain, publicId}=useParams();

    const loadApiData = async (activePage) => {
		setIsLoading(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/public-url-products/web/ids`, {activePage, domain, publicId});
        if(apiData) {
            reset({items: apiData.data.records});
            setTotalListCount(apiData.data.count);
            setPlaceholder(apiData.data.placeholder);
            setPermission(apiData.data.permission);
            setListDataCount(apiData.hasOwnProperty('data') && apiData.data.hasOwnProperty('records') && apiData.data.records.length);
            setIsLoading(false);
			setIs404(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }

    const handlePageChange = async (pNumber) => {
        if(Number(Permission)) {
            isRefSubmit.current = true;
            pageNumber.current = pNumber;
            submitButtonRef.current.click();
        } else {
            utils.redirect(`/${domain}/${publicId}?page=${pNumber}`)
        }
	}

    const onSubmit = (data) => {
        setIsSubmitted(true);
        let eId = [];
        if(childRef && childRef.hasOwnProperty('current') && Array.isArray(childRef.current)) {
            eId = childRef.current
        }
        let apiData = ajaxCall.postCall(`${config.api.generic}/public-url-products/web/ids`, {data: data.items, eId: eId});
        if(apiData) {
            toast.info('Saving your records');
            if(isRefSubmit.current) {
                setTimeout(() => {
                    utils.redirect(`/${domain}/${publicId}?page=${pageNumber.current}`)
                }, 2000);
            }
            setIsSubmitted(false);
        } else {
            toast.error('Something went wrong. Please try again :(');
            setIsSubmitted(false);
        }
    };

    const handleChildInput = (id) => {
        if(childRef && childRef.hasOwnProperty('current') && Array.isArray(childRef.current)) {
            if(!childRef.current.includes(id)) {
                childRef.current.push(id);
            }
        }
    };

    useEffect(() => {
        async function fetchData() {
			loadApiData(activePage);
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-12 col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            {(IsLoading) ? <Loading /> : (Is404 || ListDataCount === false) ? <Record404 /> : <>
                                <div className="mb-3 text-center fw-bold blockquote">PRODUCT DETAILS</div>
                                <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                                    <Controller
                                        name="items"
                                        control={control}
                                        render={({ field: { value } }) => (
                                            <>
                                                {value && value.map((item, index) => (
                                                    <CardList 
                                                        key={index}
                                                        index={index}
                                                        control={control}
                                                        errors={errors.items?.[index]}
                                                        paginationNo={activePage} 
                                                        domain={domain} 
                                                        imgCarousel={item.image}
                                                        descPlaybook={item.description_playbook}
                                                        placeholder={Placeholder}
                                                        id={item.id}
                                                        onInput={handleChildInput}
                                                        permission={Permission} 
                                                    />))
                                                }
                                            </>
                                        )}
                                    />
                                    {Number(Permission) ? <button type="submit" className="btn btn-lg bg-dark text-white text-center w-100 fw-bold mb-3 mt-3" ref={submitButtonRef} disabled={IsSubmitted}>{IsSubmitted?`Saving Data`:`Save`}</button> : null}
                                </form>   
                                <div className="row">
                                    <div className="col">
                                        <div className="d-flex justify-content-center my-2">
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={config.pagination.limitPerPage}
                                                totalItemsCount={parseInt(TotalListCount)}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => handlePageChange(e)}
                                                innerClass="pagination" 
                                                itemClass="page-item" 
                                                linkClass="page-link" 
                                                activeClass="active"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};

export default Home;
